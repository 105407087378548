<template>
  <b-modal
    id="modal-calculate"
    title="Project Calculate"
    hide-footer
    centered
    size="lg"
    @hidden="resetModal"
  >
    <b-row>
      <b-col cols="3" class="mt-1">
        <validation-provider
          name="Profit Year"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Profit Year"
            :invalid-feedback="errors[0]"
          >
            <custom-select
              v-model="form.year"
              label="text"
              :clearable="false"
              :options="years"
              placeholder="Select year"
              @input="fetchBudgeting"
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col class="d-flex align-items-center justify-content-end">
        <span class="total">{{ formatMoney(form.projection, { symbol: 'Rp', precision: 0, thousand: '.' }) }}</span>
      </b-col>
    </b-row>
    <b-row align-v="end" v-for="(item, i) in form.additionals" :key="i">
      <b-col>
        <b-form-group
          :label="`Additional ` + (i+1)"
        >
          <b-form-input
            v-model="item.additional"
            :placeholder="`Enter additional ` + (i+1)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <b-input-group
            prepend="Rp"
          >
            <money
              class="form-control"
              v-model="item.amount"
              @input="restrictInput($event)"
            />
            <b-link class="ml-2 d-flex align-items-center text-danger" @click="removeItem(i)">
              <fa-icon icon="trash" size="lg" />
            </b-link>
            <b-link v-show="form.additionals.length-1 === i" class="mt-2 ml-2" @click="addItem">
              <img src="@/assets/add-toggle.svg"/>
            </b-link>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="mt-4 d-flex justify-content-between">
      <div class="font-weight-bold">
        PROJECTION
      </div>
      <div class="projection">
        {{ formatMoney(calculateProjection, { symbol: 'Rp', precision: 0, thousand: '.' }) }}
      </div>
    </div>
    <b-row class="mt-4 mb-1">
      <b-col>
        <b-button
          class="reset"
          variant="outline-warning"
          block
          @click="cancel"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col>
        <b-button
          class="next"
          variant="warning"
          block
          @click="apply"
        >
          Apply
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'

export default {
  props: {
  },

  data: () => ({
    years: [],
    form: {
      year: null,
      projection: 0,
      additionals: [{
        additional: null,
        amount: 0
      }]
    }
  }),

  computed: {
    calculateProjection() {
      let total = 0
      this.form.additionals.map(v => {
        total += v.amount
      })
      return this.form.projection + total
    }
  },

  created() {
    this.fetchYears()
  },

  methods: {
    formatMoney,
    fetchYears() {
      let year = new Date().getFullYear() - 1
      for (let i = 0; i < 5; i++) {
        this.years[i] = year--
      }
    },
    async fetchBudgeting() {
      const { data } = await api.budget.budgetingList({
        page: 1,
        pageSize: 100,
        filters: `year==${this.form.year}`,
        sorts: ''
      })

      if (data.data.length) {
        this.form = {
          ...this.form,
          ...data.data[0]
        }
      } else {
        this.$nextTick(() => {
          this.$bvToast.toast('Data not found.', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    restrictInput(e) {
      if (e < 0 || this.form.projection.toString().includes('-')) {
        const str = this.form.projection.toString().replace('-', '')
        this.form.projection = Number(str)
      }
    },
    addItem() {
      this.form.additionals.push({
        additional: null,
        amount: 0
      })
    },
    removeItem(i) {
      if (this.form.additionals.length === 1) {
        this.form.additionals = [{
          additional: null,
          amount: 0
        }]
      } else {
        this.form.additionals.splice(i, 1)
      }
    },
    apply() {
      this.form.projection = this.calculateProjection
      this.$emit('set', this.form)
      this.cancel()
    },
    resetModal() {
      this.form = this.$options.data().form
    },
    cancel() {
      this.$bvModal.hide('modal-calculate')
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  color: #2F80ED;
}

.projection {
  color: #4CAF50;
}
</style>
